body {
  margin: 0;
}
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./static/uc.svg) center center/contain no-repeat;
}
